// Basic
$white: #fff;
$black: #282638;

// Main background
$color-primary-bg: #fafafa;

// Primary
$color-primary-default: var(--primary);
$color-primary-100: var(--primary-100);
$color-primary-90: var(--primary-90);
$color-primary-80: var(--primary-80);
$color-primary-70: var(--primary-70);
$color-primary-60: var(--primary-60);
$color-primary-50: var(--primary-50);
$color-primary-40: var(--primary-40);
$color-primary-30: var(--primary-30);
$color-primary-20: var(--primary-20);
$color-primary-10: var(--primary-10);

$color-primary-text: var(--primary-text);

// Secondary
$color-secondary-default: var(--secondary);
$color-secondary-100: var(--secondary-100);
$color-secondary-90: var(--secondary-90);
$color-secondary-80: var(--secondary-80);
$color-secondary-70: var(--secondary-70);
$color-secondary-60: var(--secondary-60);
$color-secondary-50: var(--secondary-50);
$color-secondary-40: var(--secondary-40);
$color-secondary-30: var(--secondary-30);
$color-secondary-20: var(--secondary-20);
$color-secondary-10: var(--secondary-10);

$color-secondary-text: var(--secondary-text);

// Outline buttons and links
$color-outline-text-default: var(--outline-ext);
$color-outline-text-100: var(--outline-text-100);
$color-outline-text-90: var(--outline-text-90);
$color-outline-text-80: var(--outline-text-80);
$color-outline-text-70: var(--outline-text-70);
$color-outline-text-60: var(--outline-text-60);
$color-outline-text-50: var(--outline-text-50);
$color-outline-text-40: var(--outline-text-40);
$color-outline-text-30: var(--outline-text-30);
$color-outline-text-20: var(--outline-text-20);
$color-outline-text-10: var(--outline-text-10);

// Grey
$color-grey-map: (
  100: #282638,
  90: #3f3e49,
  80: #5c5c62,
  70: #75757b,
  60: #848388,
  50: #97969f,
  40: #bbbac5,
  30: #dedde3,
  20: #eeeef0,
  10: #f8f8f8,
);

$color-grey-100: map-get($color-grey-map, 100);
$color-grey-90: map-get($color-grey-map, 90);
$color-grey-80: map-get($color-grey-map, 80);
$color-grey-70: map-get($color-grey-map, 70);
$color-grey-60: map-get($color-grey-map, 60);
$color-grey-50: map-get($color-grey-map, 50);
$color-grey-40: map-get($color-grey-map, 40);
$color-grey-30: map-get($color-grey-map, 30);
$color-grey-20: map-get($color-grey-map, 20);
$color-grey-10: map-get($color-grey-map, 10);

// Success
$color-success: #039b24;
$color-success-2: #dcf2d2;

// Errors
$color-error: #bf0f24;

// Warnings
$color-warning: #e7591c;

// Yellow
$color-yellow-10: #856404;
$color-yellow-5: #fff3cd;

// Violet
$color-violet-5: #efeef8;

// Violet
$color-violet-10: #c2c1d2;
$color-violet-5: #efeef8;

// Background
$color-background: var(--background);
$color-background-text: var(--background-text);
$color-header-text: var(--header-text);

@mixin build-color-classes($color-map, $prefix: 'color-grey') {
  @each $key, $value in $color-map {
    .#{$prefix}-#{$key} {
      color: $value;
    }
  }
}

@include build-color-classes($color-grey-map);
