@use 'sass:meta';
@import '@styles/mixins';

@mixin build-single-spacing($class, $property, $prefix: null) {
  @if $prefix {
    $prefix: '-#{$prefix}';
  }

  @each $index, $gap in $gap-values {
    .#{$class}#{$prefix}-#{$index} {
      #{$property}: $gap;
    }

    .#{$class}t#{$prefix}-#{$index} {
      #{$property}-top: $gap;
    }

    .#{$class}b#{$prefix}-#{$index} {
      #{$property}-bottom: $gap;
    }

    .#{$class}r#{$prefix}-#{$index} {
      #{$property}-right: $gap;
    }

    .#{$class}l#{$prefix}-#{$index} {
      #{$property}-left: $gap;
    }

    .#{$class}x#{$prefix}-#{$index} {
      #{$property}-left: $gap;
      #{$property}-right: $gap;
    }

    .#{$class}y#{$prefix}-#{$index} {
      #{$property}-top: $gap;
      #{$property}-bottom: $gap;
    }
  }
}

.overflow-auto {
  overflow: auto;
}

@mixin build-spacing($prefix: null) {
  @include build-single-spacing('m', 'margin', $prefix);
  @include build-single-spacing('p', 'padding', $prefix);
}

@include responsive-mixin(meta.get-mixin('build-spacing'));
