@mixin on-mobile {
  @media only screen and (max-width: $screen-md) {
    @content;
  }

  @container (max-width: 500px) {
    @content;
  }
}

@mixin on-tablet {
  @media only screen and (min-width: $screen-md + 1px) and (max-width: $screen-lg) {
    @content;
  }

  @container (min-width: 501px) and (max-width: 900px) {
    @content;
  }
}

@mixin on-desktop {
  @media only screen and (min-width: $screen-lg + 1px) {
    @content;
  }

  @container (min-width: 901px) {
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if $key == on-mobile {
      @include on-mobile {
        @content;
      }
    } @else if $key == on-tablet {
      @include on-tablet {
        @content;
      }
    } @else if $key == on-desktop {
      @include on-desktop {
        @content;
      }
    }
  }
}
