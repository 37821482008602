@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$border-radius: 8px;

.ui-dropdown {
  position: relative;
  color: $color-dropdown-fg;
  outline: none;
  width: 100%;

  &--disabled {
    color: $color-dropdown-disabled;
    pointer-events: none;
  }

  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: $border-radius;
    outline: 1px solid $color-dropdown-outline;
    background: $white;
    z-index: 1;
    position: relative;
    height: 56px;

    &:hover {
      outline: 1px solid $color-dropdown-hover;
      cursor: pointer;
    }

    &--outlined {
      outline: 2px solid $color-dropdown-opened;
    }

    &--error {
      outline: 2px solid $color-dropdown-error;
    }

    .label {
      &__placeholder {
        flex: 1;
        overflow: hidden;

        .placeholder {
          &__text {
            @include font(12px, 16px, 500);

            color: $color-dropdown-label-fg;
            transition:
              transform 150ms ease-out,
              font-size 150ms ease-out;

            &--empty.placeholder__text--closed {
              font-size: 16px;
              transform: translateY(12px);
            }
          }

          &__value {
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 24px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    text-align: start;
    flex-direction: column;
    width: 100%;
    overflow: hidden auto;
  }

  &__error-message {
    font-size: 12px;
    font-weight: 400;
    color: $color-dropdown-error;
    padding-top: 4px;

    .font-icon {
      color: inherit;
    }

    span {
      margin-right: 4px;
    }
  }
}

.ui-dropdown__modal {
  .ui-modal__footer {
    padding: 12px 20px;
  }

  .ui-modal__content {
    display: flex;
    flex-grow: 1;
  }

  .dropdown-list__item {
    padding: 12px 20px;
  }
}
