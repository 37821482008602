@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$size: 64px;
$margin: calc($size / 2);

.loader {
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &__title {
    @include font(14px, 24px, 600);
  }

  &__spinner {
    height: 100%;
    width: 100%;
    max-height: $size;
    max-width: $size;
    transform-origin: center center;
    animation: rotate 2s linear infinite;
    margin-bottom: 8px;

    .spinner__path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation:
        dash 1.5s ease-in-out infinite,
        color 6s ease-in-out infinite;
      stroke-linecap: round;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    0% {
      stroke: $color-primary-70;
    }

    100% {
      stroke: $color-secondary-70;
    }
  }
}
