@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$tile-size-mobile: 47px;
$tile-size-desktop: 54px;

@mixin calendar-cell {
  width: $tile-size-mobile;
  height: $tile-size-mobile;
  box-sizing: border-box;

  @include on-desktop {
    width: $tile-size-desktop;
    height: $tile-size-desktop;
    box-sizing: border-box;
  }
}

.ui-calendar {
  width: $tile-size-mobile * 7;

  @include on-desktop {
    width: $tile-size-desktop * 7;
  }

  * {
    font-family: Inter, sans-serif;
  }

  &__header {
    text-align: center;
  }

  &__tiles {
    display: flex;
    flex-wrap: wrap;

    .ui-calendar__week-day {
      @include calendar-cell;

      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      color: $color-calendar-week-day-fg;
      text-align: center;
      line-height: $tile-size-mobile;

      @include on-desktop {
        line-height: $tile-size-desktop;
      }
    }

    .tile {
      @include calendar-cell;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 4px;
      background-color: transparent;
      border: none;
      border-radius: 8px;
      cursor: pointer;

      &__day {
        color: $color-calendar-day-fg;
        font-size: 16px;
        line-height: 22px;
      }

      &__text {
        flex-grow: 1;
        color: $color-calendar-tile-text-fg;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
      }

      &__price {
        @include font(11px, 16px, 400);

        font-family: $font-secondary;
        color: $color-calendar-tile-price-fg;

        &-symbol {
          @include font(8px, 14px, 500);

          vertical-align: text-top;
        }
      }

      &.tile--today {
        border: 1px solid $color-calendar-today-border;
        padding: 3px;
      }

      &.tile--selected {
        background-color: $color-calendar-day-bg-selected;

        .tile__day {
          color: $color-calendar-day-fg-selected;
        }

        .tile__text {
          color: $color-calendar-tile-text-fg-selected;
        }
      }

      &:hover:not(.selected, .tile--disabled, :disabled) {
        border: 1px solid $color-calendar-day-border-disabled;
        padding: 3px;
      }

      &:disabled,
      &.tile--disabled {
        cursor: initial;

        .tile__day {
          color: $color-calendar-day-fg-disabled;
        }
      }
    }
  }
}
