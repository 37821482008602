@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.page-skeleton {
  &__section {
    background: $white;
    border-radius: 12px;
    box-shadow: $box-shadow-3-bottom;
  }

  &__search-bar {
    padding: 24px 32px;
    z-index: 10;
  }

  &__page {
    max-width: $page-max-width;
    margin: 0 auto;
  }

  &__bg-line {
    position: absolute;
    width: 100%;
  }

  &__result {
    margin-top: 115px;
    position: relative;
    background: $white;
    border-radius: 24px 24px 0 0;
    padding: 40px 16px 10px;
    height: calc(100svh - 120px);

    @include on-desktop {
      background: unset;
      width: 100%;
      padding: 0;
      margin: 0 auto;
    }

    & .page-skeleton__search-bar {
      margin-top: 80px;
      margin-bottom: 40px;
    }
  }

  &__search {
    padding: 0 20px;

    & .page-skeleton__search-bar {
      height: 330px;
      margin-top: 170px;
      margin-bottom: 90px;

      @include on-desktop {
        height: unset;
        margin-top: 300px;
        margin-bottom: 120px;
      }
    }
  }

  &__landing {
    margin-top: 270px;
    position: relative;
    background: $white;
    border-radius: 24px 24px 0 0;
    padding: 20px 20px 0;

    @include on-desktop {
      background: unset;
      padding: 0;
      margin-top: 310px;
    }

    & .landing-card__tear {
      width: calc(100% - 23px);
      transform: translateX(12px);

      &::before {
        left: calc(0% - 32px);
      }
    }
  }

  &__checkout {
    margin-top: 70px;
    margin-bottom: 150px;

    @include on-desktop {
      margin-top: 100px;
      margin-bottom: 0;
    }

    &-submit {
      bottom: 0;
      left: 0;
      position: fixed;
      width: 100%;
      z-index: 10;

      @include on-desktop {
        position: static;
      }
    }
  }
}
