@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
@import 'icon-font';

$icon-size-small: 16px;
$icon-size-medium: 20px;
$icon-size-large: 24px;

@mixin icon-size($size) {
  font-size: $size;
  width: $size;
  height: $size;
  line-height: $size;
}

.font-icon {
  display: block;
  overflow: visible;
  box-sizing: content-box;
  flex-shrink: 0;

  &__content {
    display: block;
    width: 150%;
    text-align: left;
    height: max-content;
    color: inherit;
  }

  &.small {
    @include icon-size($icon-size-small);
  }

  &.medium {
    @include icon-size($icon-size-medium);
  }

  &.large {
    @include icon-size($icon-size-large);
  }
}
