@use 'sass:meta';
@import '@styles/mixins';

@mixin build-flexbox($prefix: null) {
  @if $prefix {
    $prefix: '-#{$prefix}';
  }

  .column#{$prefix} {
    display: flex;
    flex-direction: column;
  }

  .row#{$prefix} {
    display: flex;
    flex-direction: row;
  }

  .-order-1#{$prefix} {
    order: -1;
  }

  .start#{$prefix} {
    justify-content: start;
  }

  .center#{$prefix} {
    justify-content: center;
  }

  .end {
    justify-content: end;
  }

  .space-between#{$prefix} {
    justify-content: space-between;
  }

  .items-center#{$prefix} {
    align-items: center;
  }

  .items-start#{$prefix} {
    align-items: start;
  }

  .items-end {
    align-items: end;
  }

  .items-stretch {
    align-items: stretch;
  }

  .row#{$prefix},
  .column#{$prefix} {
    [class*='cell'] {
      &.grow {
        flex-grow: 1;
        max-width: none;
      }

      &.no-grow {
        flex-grow: 0;
      }

      &.shrink {
        flex-shrink: 1;
      }

      &.no-shrink {
        flex-shrink: 0;
      }

      &.stretch {
        align-self: stretch;
      }

      &.center {
        align-self: center;
      }
    }

    &.wrap {
      flex-wrap: wrap;
    }
  }

  .cell {
    flex: 1 0 0;
    max-width: 100%;
  }

  .basis-auto#{$prefix} {
    flex-basis: auto;
  }

  @for $i from 1 through $columns-count {
    .order#{$prefix}-#{$i} {
      order: $i;
    }

    .cell#{$prefix}-#{$i} {
      flex: 0 0 auto;
      flex-basis: calc((100% / 12) * $i);
      max-width: calc((100% / 12) * $i);
    }
  }

  .w#{$prefix}-100 {
    width: 100%;
  }

  .h#{$prefix}-100 {
    height: 100%;
  }

  .hidden#{$prefix} {
    display: none;
  }

  .block#{$prefix} {
    display: block;
  }

  @each $index, $gap in $gap-values {
    .row.gap#{$prefix}-#{$index},
    .column.gap#{$prefix}-#{$index},
    .row#{$prefix}.gap#{$prefix}-#{$index},
    .column#{$prefix}.gap#{$prefix}-#{$index} {
      gap: 0;
      margin-left: -$gap;
      margin-top: -$gap;
      width: calc(100% + $gap);

      > * {
        padding-left: $gap;
        padding-top: $gap;
      }
    }
  }
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

@include responsive-mixin(meta.get-mixin('build-flexbox'));
