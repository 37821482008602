@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
@keyframes modal-in {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes modal-out {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.ui-modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 24px 24px 0 0;
  max-height: 100%;
  width: 100%;
  background-color: $white;
  z-index: $z-index-modal;

  &--full-screen {
    height: 100%;
    border-radius: 0;
  }

  &--desktop {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: max-content;
    width: max-content;
    max-height: 80%;
    border-radius: 8px;

    &.ui-modal--md {
      max-width: 650px;
    }

    &.ui-modal--sm {
      max-width: 460px;
    }

    &.ui-modal--lg {
      max-width: 986px;
      max-height: 85%;
      min-width: 40%;
    }

    &.ui-modal--full-width {
      width: 100%;
    }

    &.ui-modal--full-height {
      height: 100%;
    }
  }

  &--opened {
    animation: modal-in 300ms;

    &.ui-modal--desktop {
      animation: fade-in 300ms;
    }
  }

  &--closed {
    animation: modal-out 300ms;
    transform: translateY(100%);

    &.ui-modal--desktop {
      animation: fade-out 300ms;
      opacity: 0;
      transform: translate(-50%, -50%);
    }
  }

  &__header {
    padding: 16px 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    box-shadow: $box-shadow-1-bottom;
  }

  &__close-button {
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &__content {
    overflow: auto;
    flex-grow: 1;
  }

  &__footer {
    box-shadow: $box-shadow-1-top;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: $color-grey-100;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: $z-index-modal;
  }
}
