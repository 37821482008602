@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.journey-list {
  &__skeleton-card {
    @include gap(25px, true);

    & .ui-card__footer .ui-skeleton {
      transform: translateY(10px);
    }

    & .ui-card__footer > div > div:last-child {
      transform: translate(20px, 10px);
    }

    & .ui-card__main-content {
      padding: 20px;
    }
  }
}
