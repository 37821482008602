@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-autocomplete__tooltip {
  padding: 0;
}

.ui-autocomplete {
  position: relative;
  font-family: $font-secondary;

  &__main-input {
    @include on-mobile {
      caret-color: transparent;
    }
  }

  &__list {
    background: $white;
    overflow: auto;
    cursor: pointer;
    text-align: start;

    @include on-desktop {
      min-width: 100%;
      max-width: 300px;
    }

    &--opened {
      border-radius: 8px;
    }

    .loader {
      position: relative;
      margin: 20px 0;
    }

    .font-icon {
      padding-right: 12px;
      color: $color-autocomplete-icon;
    }
  }

  &__error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: $color-autocomplete-error-message;
    padding: 16px 20px;
  }

  &__destinations {
    margin: 15px 15px 0;
    cursor: default;
  }

  &__modal {
    &--opened {
      animation: none;
    }

    .ui-modal__header {
      z-index: 10;
    }

    .ui-autocomplete__list {
      margin-top: 10px;
    }

    & .ui-input {
      outline: none;
      padding: 0 0 0 42px;

      &-wrapper {
        margin-top: 25px;

        &__icon {
          .font-icon {
            left: 0;
          }
        }
      }

      &-reset {
        @include font(12px, 12px, 600);

        right: 8px;
        background: $color-grey-70;
        border-radius: 50%;
        color: $white;
        padding: 3px;
        width: 12px;
        height: 12px;
      }

      &:focus {
        outline: none;
      }

      &:hover:enabled:not(:focus) {
        outline: none;
      }
    }
  }
}
