@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.sdk-route {
  background-color: $white;
  border-radius: 12px;

  &__price {
    @include font(28px, 32px, 600);

    font-family: $font-secondary;
    text-align: right;
    justify-content: flex-end;
    height: 32px;
  }
}
