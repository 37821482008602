@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.counter {
  &__amount {
    width: 20px;
    text-align: center;
    user-select: none;
    white-space: nowrap;
  }

  &__label {
    @include font(16px, 24px);

    font-family: $font-secondary;
  }

  &__description {
    @include font(16px, 24px, 400);

    color: $color-grey-80;
    font-family: $font-secondary;
  }
}
