@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.message {
  text-align: center;

  &__text {
    color: $color-message-text;
    display: inline-block;
    background-color: $color-message-background;
    padding: 8px 12px;
    border-radius: 12px;
  }
}
