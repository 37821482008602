@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.connection-filters {
  &__section {
    &--hidden {
      display: none;
    }
  }

  &__section-header {
    cursor: pointer;

    &--collapsed {
      .connection-filters__section-header-title {
        padding-bottom: 0;
      }
    }
  }

  &__section-content {
    &--collapsed {
      display: none;
    }
  }
}
