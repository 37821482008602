@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$checkmark-size: 30px;

.ui-option {
  cursor: pointer;
  padding: 12px;
  background-color: $color-option-bg;
  color: $color-option-fg;
  position: relative;

  &:hover,
  &--active {
    background-color: $color-option-bg-hover;
  }

  &--selected {
    background-color: $color-option-bg-selected;

    .ui-option__label--shorten {
      padding-right: calc(24px);
    }
  }

  &:active {
    background-color: $color-option-bg-active;
  }

  &--separated {
    border-bottom: 1px solid $color-dropdown-currency-border;
  }

  &__label {
    width: 100%;

    &--shorten {
      padding-right: calc(24px + 20px);
    }
  }
}
