h1,
h2,
h3,
h4 {
  margin: 0 0 24px;
  padding: 0;
  font-family: $font-primary;

  @include on-desktop {
    margin-bottom: 32px;
  }
}

h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;

  @include on-desktop {
    font-size: 64px;
    line-height: 72px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;

  @include on-desktop {
    font-size: 32px;
    line-height: 36px;
  }
}

h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;

  @include on-desktop {
    font-size: 24px;
    line-height: 28px;
  }
}

h4 {
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;

  @include on-desktop {
    font-size: 18px;
    line-height: 24px;
  }
}

.body-12 {
  @include font(12px, 16px, 400);
}

.body-14 {
  @include font(14px, 20px, 400);
}

.body-16 {
  @include font(16px, 24px, 400);
}

.body-18 {
  @include font(18px, 24px, 400);
}

.body-20 {
  @include font(20px, 24px, 400);
}

.bold {
  font-weight: 500;
}

.bolder {
  font-weight: 600;
}
