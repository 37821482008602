@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
@mixin colored-button($color, $color-hover, $color-active, $color-fg, $color-shadow: null) {
  .ui-button {
    @include tab-focus-outline($color-active);
  }

  @if $color-shadow {
    .ui-button-shadow {
      background: color-mix(in srgb, $color-shadow 40%, transparent);
    }
  }

  &.filled .ui-button {
    color: $color-fg;
    background-color: $color;

    &:hover {
      background-color: $color-hover;
    }

    &:active {
      background-color: $color-active;
    }

    &:disabled {
      background-color: $button-color-bg-disabled;
    }
  }

  &.outline .ui-button,
  &.text .ui-button {
    color: $color;

    &:hover {
      color: $color-hover;
    }

    &:active {
      color: $color-active;
    }

    &:disabled {
      color: $button-color-bg-disabled;
    }
  }
}

.ui-button-wrapper {
  position: relative;

  .loader {
    position: absolute;
    z-index: 1;
    padding: 4px;
  }

  .ui-button-shadow {
    position: absolute;
    inset: 7.14% 4.17% -7.14%;
    filter: blur(10px);
    border-radius: 8px;
  }

  &.primary {
    @include colored-button(
      $button-color-primary,
      $button-color-primary-hover,
      $button-color-primary-active,
      $button-color-primary-fg,
      $button-color-primary-shadow
    );
  }

  &.secondary {
    @include colored-button(
      $button-color-secondary,
      $button-color-secondary-hover,
      $button-color-secondary-active,
      $button-color-secondary-fg,
      $button-color-secondary-shadow
    );
  }

  &.outline,
  &.text {
    @include colored-button(
      $button-color-outline,
      $button-color-outline-hover,
      $button-color-outline-active,
      $button-color-outline
    );
  }

  &.grey {
    @include colored-button(
      $button-color-grey,
      $button-color-grey-hover,
      $button-color-grey-active,
      $button-color-grey-fg
    );
  }

  &.white {
    @include colored-button(
      $button-color-white,
      $button-color-white-hover,
      $button-color-white-active,
      $button-color-white-fg
    );
  }

  &.backgroundText {
    @include colored-button(
      $button-color-background-text,
      $button-color-background-text-hover,
      $button-color-background-text-active,
      $button-color-background-text-fg
    );
  }

  &.outline .ui-button {
    border: 1px solid $button-color-outline-border;
  }

  &.round .ui-button {
    height: auto;
    width: auto;
    display: flex;
    border-radius: 50%;
    padding: 6px;
    font-weight: 400;
  }

  &.text .ui-button {
    padding: 0;
    width: auto;
    height: auto;
  }

  .ui-button {
    position: relative;
    height: 56px;
    padding: 0 24px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    text-align: center;
    border: none;
    pointer-events: auto;

    &:disabled {
      cursor: initial;
    }
  }

  &.fullWidth {
    .ui-button {
      width: 100%;
    }
  }
}
