@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.search-form {
  &__compound-date-select {
    display: flex;

    .ui-date-picker {
      flex-basis: 50%;

      &:first-child .ui-date-picker__input-wrapper {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }

      &:last-child .ui-date-picker__input-wrapper {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .search-form__locations {
    position: relative;
  }

  .search-form__switch-locations {
    position: absolute;
    right: 8px;
    top: calc(50%);
    transform: translateY(-50%);
    z-index: 1;
  }

  &.row {
    .search-form__dates {
      min-width: 280px;
    }

    .search-form__submit-button.cell {
      flex-grow: 2;
    }

    .search-form__locations.grow {
      flex-grow: 2;
    }

    .search-form__switch-locations {
      top: 14px;
      right: 50%;
      transform: translateX(50%);
    }
  }

  .search-form__addon,
  .search-form__accommodation,
  .search-form__seat-benefits {
    & .ui-checkbox {
      align-items: center;
    }
  }
}
