@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-date-picker-popup {
  @include gap(32px);

  cursor: initial;
  padding: 32px 24px;
  display: flex;
  border-radius: 8px;
  top: 105%;

  &__divider {
    width: 1px;
    background-color: #e5e5e8;
    align-self: stretch;
  }

  &__calendar-header {
    position: relative;
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: $color-grey-100;
    text-transform: capitalize;

    .ui-date-picker-popup__navigation-button {
      position: absolute;
      top: -10px;

      .ui-button {
        border: none;
        color: $color-grey-60 !important;

        &:hover {
          color: $color-grey-50 !important;
        }

        &:active {
          color: $color-grey-70 !important;
        }
      }

      &:not(.right) {
        left: 0;
      }

      &.right {
        right: 0;
      }
    }
  }
}
