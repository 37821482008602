@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  font-family: $font-secondary;
  background: $color-card-bg;
  color: $color-card-fg;
  border-radius: 12px;
  box-shadow: $box-shadow-3-bottom;
  width: 100%;

  &:hover {
    box-shadow: 0 2px 24px rgb(11 8 39 / 14%);
  }

  &__main {
    display: flex;
    flex-direction: column;

    @include on-desktop {
      flex-direction: row;
    }

    &--opened {
      @include on-desktop {
        border-bottom: 1px solid $color-border-fare-class;
        border-radius: 0 0 15px;
      }
    }

    &--opened#{&}--sided {
      @include on-desktop {
        border-radius: unset;
        border-bottom: 1px solid $color-border-fare-class;
      }
    }

    &-content {
      flex: 1;
      width: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    * {
      font-family: $font-secondary;
    }
  }

  &--opened {
    @include on-desktop {
      border-radius: 12px 12px 24px 24px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    z-index: 1;

    @include on-desktop {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__overlay {
    &--disabled {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-booked-bg;
      z-index: 5;
      border-radius: 12px;
      cursor: unset;

      div {
        @include font(16px, 24px);

        background: $color-card-amount;
        color: $color-card-bg;
        border-radius: 4px;
        padding: 4px 12px;
      }
    }
  }

  &--disabled {
    pointer-events: none;
  }
}
