@use 'sass:meta';

@mixin tab-focus-outline($outline-color) {
  &:focus-visible {
    outline: 2px solid $outline-color;
    outline-offset: 2px;
  }
}

@mixin font($size: 12px, $ln-height: 16px, $weight: 600) {
  font-weight: $weight;
  font-size: $size;
  line-height: $ln-height;
}

@mixin gap($gap, $vertical: false) {
  & > *:not(:last-child) {
    @if $vertical {
      margin-bottom: $gap;
    } @else {
      margin-right: $gap;
    }
  }
}

@mixin scrollbar($bar-width, $border-width) {
  ::-webkit-scrollbar {
    width: $bar-width;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-corner {
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $color-grey-40;
    border-radius: 24px;
    background-clip: padding-box;
    border: $border-width solid rgb(0 0 0 / 0%);
  }
}

@mixin on-frame {
  @at-root .frame & {
    @content;
  }
}

@mixin background-header {
  background-color: $color-background;
  color: $color-background-text;
}

@mixin background-default {
  background-color: $color-primary-bg;
  color: $black;
}

@mixin background-white {
  background-color: $white;
  color: $black;
}

@mixin responsive-mixin($mixin) {
  @include meta.apply($mixin);

  @include on-mobile {
    @include meta.apply($mixin, 'sm');
  }

  @include on-tablet {
    @include meta.apply($mixin, 'md');
  }

  @include on-desktop {
    @include meta.apply($mixin, 'lg');
  }
}
