@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$size-sm: 212px;
$size-md: 336px;
$size-lg: 400px;

.ui-popper {
  display: flex;
  font-size: 14px;
  font-family: $font-secondary;
  position: absolute;
  overflow: auto;
  width: max-content;
  border-radius: 4px;
  background: $white;
  line-height: 20px;
  padding: 4px 8px;
  z-index: $z-index-tooltip;
  cursor: default;
  text-align: center;
  overflow-x: hidden;

  * {
    word-wrap: break-word;
  }

  &--max-w-sm {
    max-width: 115px;

    @include on-desktop {
      max-width: $size-sm;
    }
  }

  &--max-w-md {
    max-width: 115px;

    @include on-desktop {
      max-width: $size-md;
    }
  }

  &--max-h-sm {
    max-height: $size-sm;
  }

  &--max-h-md {
    max-height: $size-md;
  }

  &--max-h-lg {
    max-height: $size-lg;
  }

  &--full-width {
    width: 100vw;
  }

  &.bottom {
    box-shadow: 0 -2px 16px rgb(32 35 44 / 12%);
  }

  &.top {
    box-shadow: 0 2px 16px rgb(32 35 44 / 12%);
  }

  &.right {
    box-shadow: -7px 0 16px rgb(32 35 44 / 12%);
  }

  &.left {
    box-shadow: 7px 0 16px rgb(32 35 44 / 12%);
  }
}
