@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.passenger-list {
  &__age {
    span {
      text-align: end;
    }

    &-dropdown {
      .ui-dropdown__label {
        @include font(16px, 24px, 600);

        height: auto;
        outline: none;
        padding: 0;
        min-height: unset;

        .placeholder__value {
          font-family: $font-secondary;
          text-align: end;
        }
      }

      .ui-dropdown__list {
        height: 150px;
      }

      .placeholder__text {
        display: none;
      }

      &--shifted {
        & .ui-dropdown__list {
          bottom: 100%;
          top: unset;
        }
      }
    }
  }
}
