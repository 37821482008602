@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
@import 'https://fonts.googleapis.com/css2?family=Inter:wght@200..900&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Poppins&display=swap';
@import '@styles/vars';
@import '@styles/mixins';
@import '@styles/media';
@import '@styles/typography';
@import '@styles/flexbox';
@import '@styles/spacing';

* {
  box-sizing: border-box;
  font-family: $font-primary;
}

a {
  color: $color-outline-text-70;
  text-decoration: underline;
  cursor: pointer;
}

.paper {
  background-color: $white;
  border-radius: 12px;
  box-shadow: $box-shadow-1-bottom;
}
