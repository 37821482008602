@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-tabs {
  &__content {
    display: flex;

    &--hidden {
      display: none;
    }
  }

  .ui-tabs__header-label {
    @include font(16px, 24px);

    font-family: $font-secondary;
    color: $color-tabs-label-fg;
    padding: 2px 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--disabled {
      color: $color-tabs-label-disabled;
      pointer-events: none;
    }

    &:hover {
      color: $black;
      cursor: pointer;
    }

    &:focus {
      outline: 1px solid $color-tabs-label-active-primary;
      border-radius: 5px;
    }

    &--active {
      color: $color-tabs-label-active-primary;
      border-bottom: 2px solid $color-tabs-label-active-primary;

      &:hover {
        color: $color-secondary-90;
      }
    }
  }

  &--primary {
    .ui-tabs__header {
      display: flex;
      justify-content: center;

      &-wrapper {
        display: flex;
        flex-grow: 0;
        flex-basis: fit-content;
        position: relative;

        &::before {
          content: '';
          border-bottom: 1px solid $color-tabs-border;
          position: absolute;
          width: 100%;
          left: 0;
          top: 100%;
          transform: translateY(-1px);
        }

        > div {
          margin-right: 24px;
          flex-grow: 0;
          flex-basis: auto;
          z-index: 1;

          &:last-child {
            margin-right: 0;
          }
        }

        @include on-desktop {
          min-width: 220px;
        }
      }
    }
  }

  &--outlined {
    .ui-tabs__header-label {
      @include font(14px, 20px, 500);

      border-bottom: none;
      margin: 4px;

      &--active {
        color: $color-tabs-label-active-outlined;
        background: $color-tabs-outlined-bg-active;
        border-radius: 8px;
        font-weight: 600;
        border-bottom: none;
      }

      div {
        color: $color-tabs-outlined-fg;
        padding: 8px 4px;
      }
    }

    .ui-tabs__header-wrapper {
      border-radius: 10px;
      background: $color-tabs-outlined-bg;

      @include on-desktop {
        margin: 0;
      }

      div {
        color: $color-tabs-outlined-fg;
        padding: 8px 4px;
      }
    }
  }

  &__header {
    div:not(:last-child) {
      padding-right: 16px;
    }

    &-wrapper {
      display: flex;
      width: 100%;

      > div {
        flex: 1;
        text-align: center;
      }

      @include on-desktop {
        min-width: 220px;
      }
    }
  }
}
