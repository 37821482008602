@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-radio-group {
  &.row {
    @include gap(8px);
  }

  &.column {
    @include gap(20px, true);
  }

  &__label {
    @include font(12px, 16px, 400);

    color: $color-grey-50;
  }
}
