@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-date-picker {
  position: relative;

  &__tooltip {
    max-width: unset;
    max-height: unset;
  }

  .ui-date-picker__input-wrapper {
    @include gap(8px);

    display: flex;
    align-items: center;
    outline: 1px solid $color-date-picker-outline;
    transition: outline-color 150ms;
    padding: 8px 0 8px 12px;
    border-radius: 8px;
    background: $white;
    user-select: none;
    cursor: pointer;

    &:hover {
      outline: 1px solid $color-date-picker-outline-hover;
      cursor: pointer;
    }

    &.ui-date-picker__input-wrapper--active {
      outline: 2px solid $color-date-picker-outline-active;
    }

    &.ui-date-picker__input-wrapper--error {
      outline: 2px solid $color-input-error;
    }
  }

  &__icon {
    display: flex;
    color: $color-grey-40;
  }

  &__label {
    position: absolute;
    user-select: none;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    color: $color-date-picker-label;
    z-index: 10;
    transition:
      transform 150ms ease-out,
      font-size 150ms ease-out;
    cursor: pointer;
    line-height: 18px;

    &.ui-date-picker__label--focused {
      font-size: 12px;
      transform: translateY(-100%);
    }
  }

  &__value {
    position: relative;
    flex-grow: 1;
    height: 40px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: $color-date-picker-fg;
    padding-top: 16px;
    line-height: 24px;

    span {
      white-space: nowrap;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__error-message {
    bottom: 0;
    font-size: 12px;
    font-weight: 400;
    color: $color-input-error;
    margin: 4px 0;

    .font-icon {
      color: inherit;
    }

    span {
      margin-right: 4px;
    }
  }
}
