// ---- Colors

@import '@styles/colors/common';
@import '@styles/colors/button';
@import '@styles/colors/dropdown';
@import '@styles/colors/input';
@import '@styles/colors/checkbox';
@import '@styles/colors/datePicker';
@import '@styles/colors/calendar';
@import '@styles/colors/card';
@import '@styles/colors/carousel';
@import '@styles/colors/journey-list';
@import '@styles/colors/autocomplete';
@import '@styles/colors/messages';
@import '@styles/colors/option';
@import '@styles/colors/landing';
@import '@styles/colors/tabs';
@import '@styles/colors/seatSelection';
@import '@styles/colors/connectionFilters';
@import '@styles/colors/booking';
@import '@styles/colors/seats';
@import '@styles/colors/confirmation';
@import '@styles/colors/fareClassCard';
@import '@styles/colors/extras';
@import '@styles/colors/notification';
@import '@styles/colors/weekly-list';

$page-max-width: 1200px;

// ---- Media breakpoints
$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;

// ---- Flexbox
$columns-count: 12;
/* stylelint-disable length-zero-no-unit */
$gap-values: (
  0: 0px,
  0\.5: 4px,
  1: 8px,
  2: 12px,
  3: 16px,
  4: 20px,
  5: 24px,
  6: 32px,
  7: 40px,
  8: 48px,
  9: 56px,
  10: 64px,
  11: 80px,
);
/* stylelint-enable length-zero-no-unit */

// ---- Header-line
$header-line-height-mobile: 100%;
$header-line-height-desktop: 150px;
$header-line-height-desktop-dense: 80px;

$header-line-search-height-mobile: 310px;
$header-line-search-height-desktop: 374px;

// ---- Journey-list-gap for calculation
$journey-list-mobile-gap: 175px;

// ---- Fonts
$font-primary: Poppins, Inter, sans-serif;
$font-secondary: Inter, sans-serif;

// ---- Z-index
$z-index-tooltip: 1100;
$z-index-modal: 1000;
$z-index-dropdowns: 99;
$z-index-page-foreground: 10;

// ---- Box shadow
$box-shadow-1-bottom: 0 4px 8px rgb(13 11 35 / 7%);
$box-shadow-1-top: 0 -4px 8px rgb(13 11 35 / 7%);
$box-shadow-2-bottom: 0 4px 16px rgb(11 8 39 / 16%);
$box-shadow-3-bottom: 0 2px 16px rgb(11 8 39 / 8%);
