@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-date-picker-modal {
  .ui-modal-content::-webkit-scrollbar {
    display: none;
  }

  .ui-date-picker-modal__content {
    @include gap(12px, true);

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;

    .ui-calendar__header {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      text-align: start;
      text-transform: capitalize;
    }
  }

  .ui-date-picker-modal__footer {
    padding: 16px 20px;
  }
}
