@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.passenger-dropdown__modal {
  .ui-modal__content {
    padding: 20px;
    flex-grow: 1;
  }

  .ui-modal__footer {
    padding: 12px 20px;
  }
}
