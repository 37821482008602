$color-card-bg: $white;
$color-departure-time-fg: $black;
$color-card-fg: $color-grey-100;
$color-card-amount: $color-grey-70;
$color-card-time: $color-grey-60;
$color-detailed-fare-icon: $color-grey-50;
$color-fare-class-icon-fg: $color-grey-40;
$color-detailed-fare-icon-border: $color-grey-20;
$color-transfer-time-bg: $color-violet-5;
$color-ticket-fg: $color-warning;
$color-amenities-icon-bg: #b4b3c1;
$color-fare-class-bg: #f8f8f8;
$color-divider: #eeeef0;
$color-booked-bg: #ffffff8a;
$color-border-fare-class: #e7e6ee;
$color-journey-card-icon-fg: #c2c1d2;
$color-journey-card-fares-border: $color-grey-30;
$color-journey-card-fares-selected: $color-secondary-20;
$color-journey-card-fares-btn-selected: $color-primary-80;
$color-journey-card-fares-hover: $color-secondary-10;
$color-journey-card-carrier-name: $color-grey-60;
$color-journey-card-tickets-left: $color-warning;
$color-journey-card-conditions: $color-secondary-70;
$color-journey-card-transfers-bg: $color-primary-10;
$color-journey-card-transfers-border: $color-primary-20;
