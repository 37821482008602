@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$padding-left: 16px;
$padding-left-icon: 32px;
$input-border-radius: 8px;
$path:
  101.78% -9px,
  102.55% 10.35%,
  71% 11.71%,
  71.19% 89.93%,
  98.6% 91.72%,
  99.13% -20.71%,
  106% 116%,
  -8px 115%,
  -8px -7px;

.ui-input {
  @include font(16px, 24px, 500);

  font-family: inherit;
  color: $color-input-fg;
  padding: 16px;
  border: none;
  border-radius: $input-border-radius;
  outline: 1px solid $color-input-outline;
  transition: outline-color 150ms;
  overflow: hidden;
  width: 100%;

  &-wrapper {
    display: flex;
    position: relative;

    .ui-input-label {
      position: absolute;
      user-select: none;
      pointer-events: none;
      left: $padding-left;
      top: 50%;
      transform: translateY(-50%);
      color: $color-input-label-fg;
      z-index: 5;
      transition:
        transform 150ms ease-out,
        font-size 150ms ease-out;

      &.elevated {
        font-size: 12px;
        transform: translateY(-100%);
      }
    }

    &:hover {
      .ui-input-reset {
        display: block;
      }

      &:not(.ui-input-wrapper--disabled, .ui-input-wrapper--focused) .ui-input {
        outline: 1px solid $color-input-hover;
        cursor: pointer;
      }
    }

    &--multiline {
      .ui-input-label {
        top: 24px;
      }
    }

    input[type='date'] {
      @include font(16px, 24px, 400);

      text-transform: uppercase;
      color: transparent;
      clip-path: polygon($path);
      min-height: 58px;
      background-color: transparent;

      &:not([value='']) {
        color: $color-input-fg;
      }
    }

    &--focused {
      input[type='date'] {
        color: $color-input-mask-fg;
      }

      .ui-input {
        outline: 2px solid $color-input-focus;
      }

      .ui-input-reset {
        display: block;
      }
    }

    &--labeled .ui-input {
      padding: 24px 16px 8px;
    }

    &--active {
      input[type='date'] {
        color: $color-input-fg;
      }

      .ui-input-reset {
        display: block;
      }
    }

    &--disabled .ui-input {
      color: $color-input-disabled;
    }

    &--icon-before {
      .ui-input {
        padding-left: calc($padding-left + $padding-left-icon);
      }

      .ui-input-label {
        left: calc($padding-left + $padding-left-icon);
      }
    }

    &--icon-after .ui-input {
      padding-right: calc($padding-left + $padding-left-icon);
    }

    &--resettable .ui-input {
      padding-right: 38px;
      text-overflow: ellipsis;
    }

    &--error .ui-input {
      outline: 2px solid $color-input-error;
    }

    &__icon {
      position: absolute;
      top: 50%;
      left: $padding-left;
      transform: translateY(-50%);
      color: $color-input-label-fg;

      &--after {
        right: $padding-left;
        left: auto;
      }
    }
  }

  &-error-message {
    font-size: 12px;
    font-weight: 400;
    color: $color-input-error;
    padding-top: 4px;

    .font-icon {
      color: inherit;
    }

    span {
      margin-right: 4px;
    }
  }

  &-reset {
    color: $color-grey-70;
    font-weight: 600;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;

    @include on-desktop {
      display: none;

      &:hover {
        .ui-input-reset {
          display: block;
        }
      }
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type='number'] {
    appearance: textfield;
  }
}
