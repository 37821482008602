@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$radio-size: 20px;

.ui-radio {
  @include gap(12px);

  cursor: pointer;
  display: flex;

  &.centered {
    align-items: center;
  }

  &.disabled {
    cursor: not-allowed;

    .ui-kit-radio-label {
      color: $color-grey-60;
    }
  }

  &-label {
    width: 100%;
  }

  &-input {
    @include tab-focus-outline($color-secondary-70);

    cursor: inherit;
    appearance: none;
    width: $radio-size;
    height: $radio-size;
    border-radius: $radio-size;
    border: 1px solid $color-grey-50;
    flex-shrink: 0;

    &:hover {
      border-color: $color-secondary-70;
    }

    &:disabled {
      border-color: $color-grey-40;
    }

    &:checked {
      border-width: 6px;
      border-color: $color-secondary-70;

      &:hover {
        border-color: $color-secondary-60;
      }

      &:disabled {
        border-color: $color-grey-40;
      }
    }
  }
}
