@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$checkbox-size: 20px;
$checkbox-border-radius: 2px;

.ui-checkbox {
  @include gap(12px);

  cursor: pointer;
  display: flex;

  &.disabled {
    cursor: not-allowed;
  }

  &--error {
    .ui-checkbox__input {
      border-color: $color-checkbox-error;
    }

    .ui-checkbox__label {
      color: $color-checkbox-error;
    }
  }

  &__input {
    @include tab-focus-outline($color-checkbox-outline-focused);

    margin: 0;
    cursor: inherit;
    appearance: none;
    width: $checkbox-size;
    min-width: $checkbox-size;
    height: $checkbox-size;
    border-radius: $checkbox-border-radius;
    border: 1px solid $color-checkbox-unchecked;

    &:hover {
      border-color: $color-checkbox-unchecked-hover;
    }

    &:disabled {
      border-color: $color-checkbox-unchecked-disabled;
    }

    &:checked {
      background-color: $color-checkbox-checked;
      border-color: $color-checkbox-checked;
      /* stylelint-disable string-no-newline */
      background-image: url("data:image/svg+xml;utf8,\
      <svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'> \
        <path fill-rule='evenodd' clip-rule='evenodd' d='M11.2071 0.792893C11.5976 1.18342 11.5976 1.81658 11.2071 \
                                                         2.20711L4.84853 8.56569C4.3799 9.03431 3.6201 9.03432 3.15147 \
                                                        8.56569L0.792893 6.20711C0.402369 5.81658 0.402369 5.18342 \
                                                        0.792893 4.79289C1.18342 4.40237 1.81658 4.40237 2.20711 \
                                                        4.79289L4 6.58579L9.79289 0.792893C10.1834 0.402369 10.8166 \
                                                        0.402369 11.2071 0.792893Z' fill='#{$color-checkbox-tick}'/> \
        </svg>");
      /* stylelint-enable string-no-newline */
      background-repeat: no-repeat;
      background-position: center;

      &:hover {
        background-color: $color-checkbox-checked-hover;
        border-color: $color-checkbox-checked-hover;
      }

      &:disabled {
        background-color: $color-checkbox-checked-disabled;
        border-color: $color-checkbox-checked-disabled;
      }
    }
  }
}
